import { defineStore } from "pinia";
export const useNewsStore = defineStore("news", {
  state: () => ({
    newsItems: [
      // News items in chronological order. Newer items first.
      {
        date: new Date("2024-08-23"),
        content:
          'We are currently working with <strong> <a href="https://www.linkedin.com/in/lucas-martins04/?originalSubdomain=br" target="_blank">  Lucas Gabriel Martins de Oliveira </a> </a></strong> from the <strong> <a href="https://www.iadb.org/en" target="_blank">  Inter-American Development Bank </a> </a></strong> on integrating an automated GDP forecast report for <strong> Brazil </strong> into the Nowcasting Lab. Thank you for the good cooperation, Lucas!',
      },
      {
        date: new Date("2024-06-18"),
        content:
          'We now have a <strong>Nowcasting Lab blog</a></strong>: <a href="https://blognowcastinglab.wordpress.com" target="_blank"> https://blognowcastinglab.wordpress.com</a>. In the future, we will use the blog to report on our work on the Nowcasting Lab. Thank you to <strong> <a href="https://ch.linkedin.com/in/beat-scherrer-b20007288" target="_blank"> Pepe Scherrer </a> </a></strong> for creating the blog!',
      },
      {
        date: new Date("2024-06-07"),
        content:
          "Due to a data migration at the OECD, we were temporarily unable to access important input data and therefore had to temporarily freeze the Nowcasting Lab. All missing daily forecasts have now been made up and the Nowcasting Lab is running normally again.",
      },
      {
        date: new Date("2024-04-09"),
        content:
          'We currently run different versions of the <strong> mixed-frequency DFM </strong> of <a href="https://doi.org/10.1146/annurev-economics-080217-053214"> Bok, Caratelli, Giannone, Sbordone and Tambalotti (Annual Review of Economics 2018) </a> in the background and evaluate their out-of-sample performance for crisis and non-crisis periods. Thank you to <strong> <a href="https://ch.linkedin.com/in/beat-scherrer-b20007288"> Pepe Scherrer </a> </a></strong> for the great work on this!',
      },
      {
        date: new Date("2024-01-18"),
        content:
          'We added a site showing the <strong> <a href="https://nowcastinglab.org/researchers-developers"> developers and researchers </a></strong> in the Nowcasting Lab project. If you want to participate in the project, do not hesitate to contact <a href="mailto:nowcastinglab@kof.ethz.ch">nowcastinglab@kof.ethz.ch</a>.',
      },
      {
        date: new Date("2023-12-04"),
        content:
          'We are happy to have recently hosted Andrew Li at the KOF, ETH Zürich. Andrew is a research assistant at the chair of Lyudmila Grigoryeva at the University of St. Gallen. Andrew is working with data from the Nowcasting Lab, especially the newly implemented Multi-Frequency Echo State Network forecasting model coauthored by Lyudmila and colleagues (in press at the <a href="https://www.sciencedirect.com/science/article/pii/S0169207023001085">International Journal of Forecasting</a>). <a href="https://drive.google.com/file/d/16u-PumwfPTm0Kw2YTPXT2CfBrMIKT7YT/view?usp=sharing">Photo with Heiner Mikosch, Andrew Li, Maurizio Daniele (from left to right)</a>.',
      },
      {
        date: new Date("2023-10-06"),
        content:
          'The <strong> <a href="https://www.seco.admin.ch/seco/en/home.html">Swiss State Secretariat for Economic Affairs SECO </a></strong> is now officially a supporting partner of the Nowcasting Lab. This is great, we are very happy about the new supporting partnership. You can find the link to the partners page <a href="https://nowcastinglab.org/partners">here</a>.',
      },
      {
        date: new Date("2023-10-04"),
        content:
          "The Nowcasting Lab continues to grow. <strong><a href=https://research.manchester.ac.uk/en/persons/sophie.vanhuellen>Sophie van Huellen</a></strong>, Lecturer in Development Economics at the <strong><a href=https://www.manchester.ac.uk/>University of Manchester</a></strong>, has recently joined the project. The Nowcasting Lab includes a <a href=https://arxiv.org/abs/2211.00363>GDP forecasting model</a>  developed by Sophie, Giovanni Ballarin, Lyudmila Grigoryeva, and other coauthors. The <a href=https://www.manchester.ac.uk/>University of Manchester</a> is now listed on the cooperation partner site: <a href=https://nowcastinglab.org/partners/>https://nowcastinglab.org/partners</a>.",
      },
      {
        date: new Date("2023-09-28"),
        content:
          "We have changed the methodology behind the <strong>now-/forecasts of Euro Area GDP growth</strong> on the website. Thank you to Leo Beeler for his great work! The Euro Area GDP growth now-/forecasts are now obtained by aggregating the GDP growth projections of all Euro Area member countries in the Nowcasting Lab. Previously, the Euro Area GDP growth projections were obtained by directly nowcasting euro area GDP growth and the variable selections, model parametrizations, etc. were done independently for each Euro Area member country. The direct now-/forecasts of Euro Area GDP growth are no longer displayed on the website, but run in the background and stored in our daily vintage real-time database. It is interesting to compare the two methods.",
      },
      {
        date: new Date("2023-08-28"),
        content:
          "We are happy that the Nowcasting Lab is growing. <strong><a href=https://www.grigoryeva.info>Lyudmila Grigoryeva</a></strong>, Associate Professor for Quantitative Methods at University of St. Gallen (HSG), is collaborating on the project and the University of St. Gallen now features on our cooperation partner site: <a href=https://nowcastinglab.org/partners>https://nowcastinglab.org/partners</a>.",
      },
      {
        date: new Date("2023-06-12"),
        content:
          "We are currently migrating our processes to the <strong>ETH Zurich Kubernetes cluster</strong>. With the change, we intend to improve the stability of our infrastructure. Furthermore, we will be able to integrate models written in other programming languages (e.g. Python) more easily. Despite our best effort, the website could experience temporary interruptions and delays.",
      },
      {
        date: new Date("2023-06-09"),
        content:
          "Improved now-/forecast <strong>impacts</strong> calculation implemented.",
      },
      {
        date: new Date("2023-05-15"),
        content:
          "Major improvements in the <strong>stability and sustainability of the IT background processes</strong>. Some examples: (1.) Creation of a test environment so that any code and data changes etc. can be tested in live but separately from the actual production process. (2.) Creation of automatic re-establishment routines for cases of server connection breakdowns. (3.) Automatic alerts when our data provider discontinues data series. We are happy to have IT developer <a href=https://kof.ethz.ch/das-institut/personen/person-detail.MzEyMzMy.TGlzdC81NzgsODQ4OTAwOTg=.html>Stéphane Bisinger</a> with us since fall 2022.",
      },
      {
        date: new Date("2023-03-02"),
        content:
          "Improved <strong>elastic net variable pre-selection</strong> implemented.",
      },
      {
        date: new Date("2023-02-06"),
        content:
          "Research stay of <a href=https://www.uni-mannheim.de/gess/programs/cdse/our-students/2018/giovanni-ballarin>Giovanni Ballarin</a> (University of Mannheim) at KOF, ETH Zurich for implementation of a novel <strong>Multi-Frequency Echo State Network</strong> originating from Reservoir Computing into the Nowcasting Lab. <a href=https://drive.google.com/file/d/1rJDNMHxzm4k6mz3-SU4VvZlnhrWd95Gv/view?usp=share_link>Group photo</a> with M. Daniele, P. Kronenberg, G. Ballarin, and H. Mikosch (from left to right).",
      },
      {
        date: new Date("2022-12-19"),
        content:
          "New features have been implemented to exclude discontinued time series while keeping their history in the real-time database for backtesting purposes. Thank you to Beat ''Pepe'' Scherrer for his great work!",
      },
      {
        date: new Date("2022-12-09"),
        content:
          "All variable descriptions of the currently 3862 time series in the Nowcasting Lab have been reviewed and updated if necessary. The variable descriptions are available in the Data Release lists on the Detail Report pages. Thank you to Saikrishnan ''Sai'' Saisubramaniam for his great work!",
      },
      {
        date: new Date("2022-11-23"),
        content:
          "<strong>USA</strong> integrated into the Nowcasting Lab. Currently running in test mode.",
      },
      {
        date: new Date("2022-11-17"),
        content:
          '<strong>Romania</strong> and <strong>Bulgaria</strong> integrated into the Nowcasting Lab on behalf of <a href="https://ec.europa.eu/info/departments/economic-and-financial-affairs_en">DG ECFIN, European Commission</a>. Currently running in test mode. Thank you to DevOps Engineer Severin Thöni for his great competence and work input. Thank you as well to Saikrishnan "Sai" Saisubramaniam and to Beat "Pepe" Scherrer for their great work.',
      },
      {
        date: new Date("2022-10-06"),
        content:
          'The European Commission (<a href="https://ec.europa.eu/info/departments/economic-and-financial-affairs_en">DG ECFIN</a>) is now officially a collaboration partner of the Nowcasting Lab. Find the link to the partners page <a href="https://nowcastinglab.org/partners">here.</a>',
      },
      {
        date: new Date("2022-09-01"),
        content:
          "(1.) MIDAS, U-MIDAS and Bridge models displayed on the website are now estimated excluding the COVID-19 period (2020Q1ff). This improves the forecasting performance. Model forecasts based on full expanding window estimation are still produced and stored in the Nowcasting Lab real-time database. (2.) All forecasts displayed on the website now rely on the improved variable selection (see previous news post).", // "This improves the forecasting performance." -- Hier später unser NCL-Papier verlinken.
      },
      {
        date: new Date("2022-08-07"),
        content:
          "Elastic Net variable selection mechanism substantially improved. Big thanks to Joppe de Bruin for great work!",
      },
      {
        date: new Date("2022-07-14"),
        content: "News site added to the Nowcasting Lab website.",
      },
      {
        date: new Date("2022-07-11"),
        content:
          'Nowcasting Lab project presented at the <a href="https://forecasters.org/events/symposium-on-forecasting/">International Symposium on Forecasting (ISF) 2022</a>, Oxford. Here is a <a href=https://www.youtube.com/watch?v=77lhVGrW64I&list=PLUxduCQo6_X3oChP5z08pqp66-mG4Z9SA&index=62&t=2683s>Youtube video</a>.',
      },
      {
        date: new Date("2022-06-28"),
        content: "Plausible website tracking implemented.",
      },
      {
        date: new Date("2022-06-01"),
        content:
          'Romania and Bulgaria will be integrated into the lab on behalf of the <a href="https://ec.europa.eu/info/departments/economic-and-financial-affairs_en">Directorate-General for Economic and Financial Affairs, European Commission</a>.',
      },
      {
        date: new Date("2022-05-23"),
        content:
          '<b>Portugal</b> integrated into the Nowcasting Lab on behalf of <a href="https://www.gpeari.gov.pt/web/pt">GPEARI, Ministry of Finance of Portugal</a>. Currently running in test mode.',
      },
      {
        date: new Date("2022-05-15"),
        content:
          "Inclusion of US real-time data into the Nowcasting Lab database completed.",
      },
      {
        date: new Date("2022-04-06"),
        content:
          "Transition from Refinitiv Datastream to Macrobond as main dataprovider completed.",
      },
      {
        date: new Date("2022-02-21"),
        content: "Nowcasting Lab project presented at the Swiss National Bank.",
      },
      {
        date: new Date("2022-02-04"),
        content:
          "Nowcasting Lab project presented in the research seminar of the German Economic Institute (Institut der Deutschen Wirtschaft).",
      },
      {
        date: new Date("2022-01-31"),
        content:
          "Inclusion of benchmark models (random walk, rolling mean, iterative and direct AR, ARIMA) on the Nowcasting Lab website.",
      },
      {
        date: new Date("2022-01-11"),
        content:
          "Nowcasting Lab project presented at the Directorate-General for Economic and Financial Affairs, European Commission.",
      },
      {
        date: new Date("2022-01-10"),
        content:
          "Nowcasting Lab project presented in the research seminar of the Halle Institute for Economic Research (Institut für Wirtschaftsforschung Halle).",
      },
    ],
  }),
  getters: {
    getLatestNewsItem: (state) => state.newsItems[0],
  },
});
