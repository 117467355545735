<template>
  <div class="l-stack l-gap-2 l-padded">
    <loading-overlay v-if="isLoading" />
    <div>
      <div
        class="title is-4 title-align-center"
        v-if="targetVariable === 'gdp'"
      >
        <ncl-flag :iso="country" />
        {{ countryName }} {{ targetPeriod }} {{ indicatorUpperCaseName }}
        {{ forecastType }}
      </div>
      <div class="title is-4" v-else>
        {{ countryName }} {{ targetPeriod }} {{ forecastType }}
      </div>
    </div>
    <div class="columns">
      <div class="level-item">
        <div class="column col-4">
          <span class="subheading"
            >Select model
            <span id="models-info">
              <ncl-info-icon
                text="Click for information on models"
                location="right"
              />
            </span>
          </span>
          <v-chip-group
            mandatory
            selected-class="text-primary"
            v-model="dataModel"
          >
            <v-chip
              v-for="(key, model) in dataModels"
              :key="key"
              :value="model"
            >
              {{ key }}
            </v-chip>
          </v-chip-group>
        </div>
      </div>
    </div>
    <div class="columns is-multiline">
      <div class="column">
        <div class="box">
          <div class="level">
            <div class="level-item">
              <div class="column">
                <div class="heading">Forecast</div>
                <div class="title" v-if="currentForecast >= 0">
                  +{{ currentForecast }}%
                </div>
                <div class="title" v-else-if="currentForecast < 0">
                  {{ currentForecast }}%
                </div>
                <div class="title" v-else>
                  <span class="icon">
                    <font-awesome-icon
                      class="title"
                      :icon="['fa', 'minus']"
                    ></font-awesome-icon>
                  </span>
                </div>
              </div>
            </div>
            <div class="level-item">
              <ncl-info-icon :text="forecastTooltip" location="right" />
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="box">
          <div class="level">
            <div class="level-item">
              <div class="column">
                <div class="heading no-wrap">Lower Bound</div>
                <div class="title" v-if="currentLowerBound >= 0">
                  +{{ currentLowerBound }}%
                </div>
                <div class="title" v-else-if="currentLowerBound < 0">
                  {{ currentLowerBound }}%
                </div>
                <div class="title" v-else>
                  <span class="icon">
                    <font-awesome-icon
                      class="title"
                      :icon="['fa', 'minus']"
                    ></font-awesome-icon>
                  </span>
                </div>
              </div>
            </div>
            <div class="level-item">
              <ncl-info-icon
                text="Lower bound for the now-/forecast as measured by: now-/forecast - 1.28 * RMSFE. This corresponds to a two-sided 90% confidence interval."
                location="bottom"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="box">
          <div class="level">
            <div class="level-item">
              <div class="column">
                <div class="heading no-wrap">Upper Bound</div>
                <div class="title" v-if="currentUpperBound >= 0">
                  +{{ currentUpperBound }}%
                </div>
                <div class="title" v-else-if="currentUpperBound < 0">
                  {{ currentUpperBound }}%
                </div>
                <div class="title" v-else>
                  <span class="icon">
                    <font-awesome-icon
                      class="title"
                      :icon="['fa', 'minus']"
                    ></font-awesome-icon>
                  </span>
                </div>
              </div>
            </div>
            <div class="level-item">
              <ncl-info-icon
                text="Upper bound for the forecast as measured by: now-/forecast + 1.28 * RMSFE. This corresponds to a two-sided 90% confidence interval."
                location="bottom"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="box">
          <div class="level">
            <div class="level-item">
              <div class="column">
                <div class="heading">7-Day Trend</div>
                <div class="title">
                  <span class="icon">
                    <font-awesome-icon
                      class="title"
                      v-if="getCurrentTrend > 0"
                      :icon="['fa', 'long-arrow-up']"
                      :transform="{ rotate: 45 }"
                    ></font-awesome-icon>
                    <font-awesome-icon
                      class="title"
                      v-if="getCurrentTrend === 0"
                      :icon="['fa', 'long-arrow-up']"
                      :transform="{ rotate: 90 }"
                    ></font-awesome-icon>
                    <font-awesome-icon
                      class="title"
                      v-else-if="getCurrentTrend < 0"
                      :icon="['fa', 'long-arrow-down']"
                      :transform="{ rotate: -45 }"
                    ></font-awesome-icon>
                    <font-awesome-icon
                      class="title"
                      v-else-if="!getCurrentTrend"
                      :icon="['fa', 'minus']"
                    ></font-awesome-icon>
                  </span>
                </div>
              </div>
            </div>
            <div class="level-item">
              <ncl-info-icon
                text="Development of the forecast as compared to 7 days ago. An arrow pointing upwards indicates an upward trend, an arrow pointing downwards indicates a downward trend, and an arrow pointing sideways indicates a sideways trend. During the first seven days of the forecast period, the trend is built by comparing the current forecast with the first forecast in the forecast period."
                location="bottom"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="box">
          <div class="level">
            <div class="level-item">
              <div class="column">
                <div class="heading">RMSFE</div>
                <div class="title" v-if="currentRMSFE">
                  {{ currentRMSFE }}pp
                </div>
                <div class="title" v-else>
                  <span class="icon">
                    <font-awesome-icon
                      class="title"
                      :icon="['fa', 'minus']"
                    ></font-awesome-icon>
                  </span>
                </div>
              </div>
            </div>
            <div class="level-item">
              <ncl-info-icon
                text="Now-/Forecast precision as measured by the root mean squared now-/forecast error based on the now-/forecasts of the past eight periods."
                location="bottom"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="box">
          <div class="level">
            <div class="level-item">
              <div class="column">
                <div class="heading">Bias</div>
                <div class="title" v-if="currentBias >= 0">
                  +{{ currentBias }}
                </div>
                <div class="title" v-else-if="currentBias < 0">
                  {{ currentBias }}
                </div>
                <div class="title" v-else>
                  <span class="icon">
                    <font-awesome-icon
                      class="title"
                      :icon="['fa', 'minus']"
                    ></font-awesome-icon>
                  </span>
                </div>
              </div>
            </div>
            <div class="level-item">
              <ncl-info-icon
                text="Bias as measured by the average of the now-/forecast errors of the past eight periods."
                location="left"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="columns">
        <div class="column is-three-fifths">
          <p class="subtitle is-4 title-align-center">Forecast History</p>
          <forecast-history
            @activedaytimechange="onCurrentDateTimeChange"
            @activecategorieschange="onActiveCategoriesChange"
            :country="this.$route.query.country"
            :targetperiod="this.$route.query.targetperiod"
            :targetvariable="this.$route.query.targetvariable"
            :indicator="this.$route.query.indicator"
            :model="this.dataModel"
          />
        </div>
        <div class="column is-two-fifths">
          <p class="subtitle is-4 title-align-center">
            Forecast Distribution for {{ currentDayRobust }}
            <ncl-info-icon
              text='If model option "Bridge", "MIDAS" or "U-MIDAS" is selected, the forecast dirtribution chart shows all individual forecasts, which get pooled to produce one (pooled) forecast. If model option "DFM", "Random Walk", "Rolling Mean", "Iterative AR", "ARIMA", or "Direct AR" is selected, the chart is not applicable since no pooling of individual forecasts occurs for the dynamic factor model. If model option "Pooled" is selected, the forecast distribution chart shows the four forecasts resulting from the four different model classes.'
              location="bottom"
            />
          </p>
          <div
            v-if="
              [
                'dfm',
                'randomwalk',
                'rollmean',
                'ar_iterative_bic',
                'arima',
                'ar_direct_bic',
              ].indexOf(dataModel) >= 0
            "
            class="subtitle is-4"
          >
            <p class="text-not-applicable">Not applicable</p>
          </div>
          <highcharts v-else :options="histogramOptions"></highcharts>

          <p class="subtitle is-4 title-align-center">
            Data Releases for {{ currentDayRobust }}
          </p>
          <div class="container columns is-multiline">
            <div class="column text-center">
              <p style="font-size: 13px">Forecast before releases</p>
              <p v-if="indicatorValuePreviousDay">
                {{ indicatorValuePreviousDay }}
              </p>
              <font-awesome-icon
                v-else
                :icon="['fa', 'minus']"
              ></font-awesome-icon>
            </div>
            <div class="column text-center">
              <p style="font-size: 13px">Forecast after releases</p>
              <p v-if="currentForecast">{{ currentForecast }}</p>
              <font-awesome-icon
                v-else
                :icon="['fa', 'minus']"
              ></font-awesome-icon>
            </div>
            <div class="column text-center">
              <p style="font-size: 13px">Change in forecast</p>
              <p v-if="indicatorValueDifferenceToPreviousDay !== null">
                {{ indicatorValueDifferenceToPreviousDay }}
              </p>
              <font-awesome-icon
                v-else
                :icon="['fa', 'minus']"
              ></font-awesome-icon>
            </div>
          </div>
          <release-table
            :active-categories="activeCategories"
            style="width: 100%; height: 300px"
            @row-click="tableRowClick"
          ></release-table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="columns">
        <div class="column">
          <p class="subtitle is-4 title-align-center">
            {{ historicSeriesChartTitle }}
          </p>
          <highcharts :options="historicSeriesOptions"></highcharts>
        </div>
      </div>
    </div>

    <!-- release info modal -->
    <v-dialog v-model="showReleaseInfo">
      <ncl-release-modal
        :releaseDate="currentDayRobust"
        :release="releaseInfo"
        @close="showReleaseInfo = false"
      />
    </v-dialog>

    <!-- model info modal -->
    <v-dialog activator="#models-info">
      <template v-slot="{ isActive }">
        <ncl-model-modal @close="isActive.value = false" />
      </template>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState, mapStores } from "pinia";
import { Chart } from "highcharts-vue";
import { usePlausible } from "v-plausible/vue";

import ForecastHistory from "@/components/ForecastHistory.vue";
import ReleaseTable from "@/components/ReleaseTable.vue";
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import NclFlag from "@/components/NclFlag.vue";
import NclInfoIcon from "@/components/NclInfoIcon.vue";
import NclReleaseModal from "@/components/NclReleaseModal.vue";
import NclModelModal from "@/components/NclModelModal.vue";
import { dateToQuartal, formatDate } from "@/utils";
import { useCountryStore } from "@/stores/country";
import { useDetailStore } from "@/stores/detail";

export default {
  setup() {
    const { trackPageview, trackEvent } = usePlausible();
    return { trackPageview, trackEvent };
  },
  mounted() {
    this.trackPageview({
      url: this.getTrackableUrl(),
    });
  },
  head() {
    return {
      title: `${this.country.toUpperCase()} ${this.indicatorUpperCaseName} ${
        this.targetPeriod
      }`,
      ...(this.countryIcon
        ? { link: [{ rel: "icon", href: this.countryIcon }] }
        : {}),
    };
  },
  components: {
    ReleaseTable,
    highcharts: Chart,
    ForecastHistory,
    LoadingOverlay,
    NclFlag,
    NclInfoIcon,
    NclReleaseModal,
    NclModelModal,
  },
  data: function () {
    const currentModel = this.$route.query.model;
    const baseDataModels = {
      all: "Pooled",
      bridge_Q419: "Bridge",
      amidas_Q419: "MIDAS",
      umidas_Q419: "U-MIDAS",
      dfm: "DFM",
      randomwalk: "Random Walk",
      rollmean: "Rolling Mean",
      ar_iterative_bic: "Iterative AR",
      arima: "ARIMA",
      ar_direct_bic: "Direct AR",
    };
    const dataModels = !currentModel
      ? baseDataModels
      : Object.keys(baseDataModels).includes(currentModel)
        ? baseDataModels
        : { ...baseDataModels, [currentModel]: currentModel };
    return {
      country: this.$route.query.country,
      countryIcon: null,
      targetVariable: this.$route.query.targetvariable,
      targetPeriod: this.$route.query.targetperiod,
      isLoading: true,
      dataModels,
      impactCategoriesActive: [],
      activeCategories: [],
      showReleaseInfo: false,
      releaseInfo: {
        title: null,
        description: null,
        category: null,
        color: null,
        source: null,
        unit: null,
        previousValue: null,
        actualValue: null,
        referencePeriod: null,
        impact: null,
      },
    };
  },
  methods: {
    ...mapActions(useDetailStore, [
      "loadHistogramData",
      "loadGrowthHistoryData",
      "loadMeasuresData",
      "loadReleaseData",
      "setActiveDay",
    ]),
    async loadIcon() {
      const icon = await import(`../assets/icons/${this.country}.png?url`);
      this.countryIcon = icon.default;
    },
    async loadData(onlyWithDate = false) {
      const promises = [
        this.loadHistogramData({
          country: this.country,
          targetVariable: this.targetVariable,
          targetPeriod: this.targetPeriod,
          model: this.dataModel,
          date: this.currentDaySelected,
        }),
        this.loadReleaseData({
          country: this.country,
          targetVariable: this.targetVariable,
          targetPeriod: this.targetPeriod,
          model: this.dataModel,
          date: this.currentDaySelected,
        }),
      ];
      if (onlyWithDate) {
        return await Promise.all(promises);
      }
      return await Promise.all([
        ...promises,
        this.loadMeasuresData({
          country: this.country,
          targetVariable: this.targetVariable,
          targetPeriod: this.targetPeriod,
          model: this.dataModel,
        }),
        this.loadGrowthHistoryData({
          country: this.country,
          targetVariable: this.targetVariable,
          model: this.dataModel,
        }),
      ]);
    },
    onCurrentDateTimeChange(val) {
      if (!val) {
        return;
      }
      this.setActiveDay(val.x);
    },
    onActiveCategoriesChange(newActiveCategories) {
      this.activeCategories = newActiveCategories;
    },
    tableRowClick(index, releaseData) {
      const releasesForDate = this.releaseTableData[this.currentDaySelected];
      const release = releasesForDate[index];
      const releaseCategoryName = release.category;
      const releaseCategoryColor =
        this.activeCategories[
          this.activeCategories.findIndex(
            (obj) => obj.name === releaseCategoryName,
          )
        ].color;
      this.releaseInfo = {
        title: release.title,
        description: releaseData.description,
        category: releaseCategoryName,
        color: releaseCategoryColor,
        source: release.source ? release.source : "No source",
        unit: release.unit ? release.unit : "No unit",
        previousValue: release.previousValue
          ? Number.parseFloat(release.previousValue).toFixed(2)
          : "No previous value",
        actualValue: release.actualValue
          ? Number.parseFloat(release.actualValue).toFixed(2)
          : "No current value",
        referencePeriod: release.referencePeriod
          ? release.referencePeriod
          : "No reference period",
        impact: release.impact
          ? Number.parseFloat(release.impact).toPrecision(2)
          : 0,
      };
      this.showReleaseInfo = true;
    },
    // Adds query parameters as part of the path so plausible
    // tracks them. By default it discards all query params
    // for data protection reasons.
    getTrackableUrl() {
      const params = ["country", "targetvariable", "targetperiod"];
      const url = new URL(location.href);
      const queryParams = new URLSearchParams(location.search);
      let customUrl = url.protocol + "//" + url.hostname + url.pathname;
      for (const paramName of params) {
        const paramValue = queryParams.get(paramName);
        if (paramValue) customUrl = customUrl + "/" + paramValue;
      }
      return encodeURI(customUrl);
    },
  },
  computed: {
    ...mapState(useCountryStore, ["areas"]),
    ...mapStores(useDetailStore),
    ...mapState(useDetailStore, [
      "getCurrentForecast",
      "getCurrentTrend",
      "getHistogramData",
      "getGrowthHistoryData",
      "getGrowthHistoryForecastData",
      "getCurrentRMSFE",
      "getCurrentLowerBound",
      "getCurrentUpperBound",
      "getCurrentBias",
      "getOutputData",
    ]),
    ...mapState(useDetailStore, {
      currentDaySelected: "getActiveDay",
      releaseTableData: "getReleaseData",
    }),
    dataModel: {
      get() {
        return this.detailStore.model;
      },
      set(value) {
        this.trackEvent("modelselect", {
          props: {
            model: value,
            country: this.country,
            targetVariable: this.targetVariable,
            targetPeriod: this.targetPeriod,
            indicator: this.$route.query.indicator ?? "en",
          },
        });
        this.detailStore.model = value;
        this.loadData();
        this.$router.push({
          name: this.$route.name,
          params: this.$route.params,
          query: { ...this.$route.query, model: value },
        });
      },
    },
    historicSeriesOptions() {
      let options = {
        chart: {
          style: {
            fontFamily: "IBM Plex Sans",
          },
        },
        title: {
          text: "",
          style: {
            fontWeight: "bold",
          },
        },
        xAxis: {
          type: "datetime",
          title: {
            text: "Date of observation",
          },
          dateTimeLabelFormats: {
            month: "%b %Y",
            year: "%b %Y",
          },
        },
        yAxis: {
          title: {
            text: "Percentage (%)",
          },
        },
        tooltip: {
          shared: true,
          formatter: function () {
            let date = new Date(this.x);
            let quartal = dateToQuartal(date);
            return this.points.reduce(
              function (s, point) {
                return (
                  s +
                  "<br/>" +
                  point.series.name +
                  ": " +
                  point.y.toFixed(2) +
                  "%"
                );
              },
              "<b>Q" + quartal + " " + date.getFullYear() + "</b>",
            );
          },
        },
        series: [
          {
            showInLegend: false,
            data: [],
          },
          {
            showInLegend: false,
            lineWidth: 0,
            marker: {
              enabled: true,
              radius: 5,
            },
            states: {
              hover: {
                lineWidthPlus: 0,
              },
            },
            data: [],
          },
        ],
        plotOptions: {
          line: {
            marker: {
              enabled: false,
            },
          },
        },
      };

      if (this.targetVariable === "gdp") {
        options.yAxis.title.text = "Quarter-on-quarter growth in %";
      }

      options.series[0].data = this.getGrowthHistoryData;
      options.series[1].data = this.getGrowthHistoryForecastData;

      options.series[0].name =
        this.targetVariable === "gdp"
          ? this.indicatorUpperCaseName + " growth"
          : this.indicatorUpperCaseName;
      options.series[1].name =
        this.targetVariable === "gdp"
          ? this.indicatorUpperCaseName + " growth forecast"
          : this.indicatorUpperCaseName;

      return options;
    },
    histogramOptions() {
      let options = {
        chart: {
          style: {
            fontFamily: "IBM Plex Sans",
          },
        },
        title: {
          text: "",
          style: {
            fontWeight: "bold",
          },
        },
        xAxis: {
          title: {
            text: "Bin Mean",
          },
          format: "{value:.2f}",
        },
        yAxis: {
          title: {
            text: "Frequency of forecasts (per bin)",
          },
        },
        tooltip: {
          formatter: function () {
            return (
              "Bin Mean: " +
              "<b>" +
              this.point.x.toFixed(2) +
              "%</b><br>" +
              "Forecast frequency: " +
              "<b>" +
              this.point.y.toFixed(0) +
              "</b>"
            );
          },
        },
        series: [
          {
            type: "column",
            pointPlacement: "between",
            showInLegend: false,
            data: [],
          },
        ],
        lang: {
          noData: "No data to display",
        },
        noData: {
          style: {
            fontFamily: "IBM Plex Sans",
            fontWeight: "bold",
            fontSize: "15px",
            color: "#303030",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0,
            borderWidth: 1,
            groupPadding: 0,
          },
        },
      };

      if (this.targetVariable === "gdp") {
        options.xAxis.title.text =
          "Quarter-on-quarter growth forecast in % (bin mean)";
      }

      options.series[0].data =
        this.getHistogramData[this.currentDaySelected] || [];

      return options;
    },
    historicSeriesChartTitle() {
      if (this.targetVariable === "gdp") {
        return "GDP Growth: Data History Including Forecasts";
      }
      return "GDP Growth: Data History Including Forecasts";
    },
    currentDayRobust() {
      return this.formatSelectedDay ? this.formatSelectedDay : "Unknown";
    },
    currentForecast() {
      return this.getCurrentForecast?.toFixed(2);
    },
    forecastType() {
      if (this.targetVariable === "gdp") {
        return `Growth Forecast as of ${this.currentDayRobust}`;
      }
      return "";
    },
    forecastTooltip() {
      if (this.targetVariable === "gdp") {
        return `Forecast for ${this.countryAdjective} quarter-on-quarter ${this.indicatorUpperCaseName} growth ${this.targetPeriod} in % as of ${this.currentDayRobust}`;
      }
      return "";
    },
    countryName() {
      let countryObject = this.areas.find((ele) => ele.code === this.country);
      return countryObject.name;
    },
    countryAdjective() {
      let countryAdj = "";
      if (this.country !== "ea_agg") {
        let countryObject = this.areas.find((ele) =>
          ele.countries.includes(this.country.toUpperCase()),
        );
        countryAdj = countryObject.adjective;
      }
      return countryAdj;
    },
    indicatorUpperCaseName() {
      return this.targetVariable.toUpperCase();
    },
    formatSelectedDay() {
      if (this.currentDaySelected) {
        return formatDate(this.currentDaySelected);
      }
      return "";
    },
    indicatorValuePreviousDay() {
      let index = this.getOutputData.findIndex(
        (obj) => obj.x === this.currentDaySelected,
      );
      if (index > 0) {
        let previousDay = this.getOutputData[index - 1];
        return previousDay.y.toFixed(2);
      }
      return null;
    },
    indicatorValueDifferenceToPreviousDay() {
      if (
        this.indicatorValuePreviousDay !== null &&
        this.currentForecast !== null
      ) {
        return (this.currentForecast - this.indicatorValuePreviousDay).toFixed(
          2,
        );
      }
      return null;
    },
    currentRMSFE() {
      let data = this.getCurrentRMSFE;
      if (data) {
        return data.toFixed(2);
      }
      return null;
    },
    currentLowerBound() {
      let data = this.getCurrentLowerBound;
      if (data) {
        return data.toFixed(2);
      }
      return null;
    },
    currentUpperBound() {
      let data = this.getCurrentUpperBound;
      if (data) {
        return data.toFixed(2);
      }
      return null;
    },
    currentBias() {
      let data = this.getCurrentBias;
      if (data) {
        return data.toFixed(2);
      }
      return null;
    },
  },
  async created() {
    this.isLoading = true;
    const model = this.$route.query.model ?? "all";
    if (this.detailStore.model != model) {
      this.detailStore.model = model;
    }
    await Promise.all([this.loadIcon(), this.loadData()]);
    this.isLoading = false;
  },
  watch: {
    currentDaySelected() {
      this.loadData(true);
    },
  },
};
</script>

<style lang="scss">
.no-wrap {
  white-space: nowrap;
}

.box {
  padding: 0.75rem;
}

hr {
  margin-top: 0;
}

.button:focus {
  border-color: rgb(219, 219, 219);
}

.button:focus:not(:active),
.button.is-focused:not(:active) {
  box-shadow: none;
}

.button-label {
  margin-right: 1rem;
}

.button.is-primary,
.button.is-primary:hover,
.button.is-primary:active {
  background-color: #7cb5ec;
}

.button.is-primary:focus:not(:active),
.button.is-primary.is-focused:not(:active) {
  box-shadow: none;
}

.text-not-applicable {
  font-weight: bold;
  text-align: center;
}
</style>
