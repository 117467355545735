<template>
  <div id="app" class="container is-widescreen">
    <router-view></router-view>
    <ncl-footer />
  </div>
</template>

<script>
import NclFooter from "@/components/NclFooter.vue";
export default {
  name: "app",
  components: { NclFooter },
};
</script>

<style lang="scss">
// Import global CSS Variables (Theme)
@import "./styles/variables.scss";

@import "./styles/base.scss";
@import "./styles/layout.scss";
@import "./styles/utilities.scss";

#app {
  color: $color-text;
  font-family: "IBM Plex Sans";
}
</style>
