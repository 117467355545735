<template>
  <div class="l-stack l-gap-2 l-padded">
    <loading-overlay v-if="isLoading" />
    <nav
      class="navbar is-fixed-top"
      role="navigation"
      aria-label="dropdown navigation"
    >
      <div class="container">
        <div class="navbar-brand" style="align-items: center">
          <div class="navbar-item">
            <div id="lab-info">
              <ncl-info-icon
                text="Click for more information on the lab."
                location="bottom"
              />
            </div>
            <h1
              class="title is-size-2-fullhd is-size-3-widescreen is-size-4-desktop is-size-4-touch"
            >
              The Nowcasting Lab
            </h1>
          </div>
          <div class="navbar-item is-hidden-mobile is-hidden-desktop">
            <ncl-flag :iso="countryStore.activeCountry.code" />
          </div>
          <div class="navbar-item is-hidden-mobile is-hidden-desktop">
            <p>{{ countryStore.activeIndicator.name }}</p>
          </div>
          <div class="navbar-item is-hidden-mobile is-hidden-desktop">
            <p>{{ activeTargetPeriod }}</p>
          </div>
          <div class="navbar-item is-hidden-mobile is-hidden-desktop">
            <span v-if="currentIndicatorValue">{{
              currentIndicatorValue
            }}</span>
            <span v-else>No data</span>
          </div>
          <div class="navbar-item is-hidden-mobile is-hidden-desktop">
            <font-awesome-icon
              class="title is-6"
              v-if="currentTrend > 0"
              :icon="['fa', 'long-arrow-up']"
              :transform="{ rotate: 45 }"
            ></font-awesome-icon>
            <font-awesome-icon
              class="title is-6"
              v-if="currentTrend === 0"
              :icon="['fa', 'long-arrow-up']"
              :transform="{ rotate: 90 }"
            ></font-awesome-icon>
            <font-awesome-icon
              class="title is-6"
              v-else-if="currentTrend < 0"
              :icon="['fa', 'long-arrow-down']"
              :transform="{ rotate: -45 }"
            ></font-awesome-icon>
            <font-awesome-icon
              class="title is-6"
              v-else-if="!currentTrend && !currentIndicatorValue"
              :icon="['fa', 'minus']"
            ></font-awesome-icon>
          </div>
          <div class="navbar-item is-hidden-mobile is-hidden-desktop">
            <detail-report-link :active-target-period="activeTargetPeriod" />
          </div>
          <a
            role="button"
            class="navbar-burger"
            aria-label="menu"
            aria-expanded="false"
            @click="isOpen = !isOpen"
            v-bind:class="{ 'is-active': isOpen }"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div class="navbar-menu" v-bind:class="{ 'is-active': isOpen }">
          <div class="navbar-item has-dropdown is-hoverable">
            <span class="navbar-link is-boxed">
              <ncl-flag :iso="countryStore.activeCountry.code" />
              &nbsp; {{ countryStore.activeCountry.name }}
            </span>
            <div class="navbar-dropdown is-boxed">
              <div
                class="navbar-item dropdown-option"
                v-for="(area, key, index) in countryStore.areas"
                :key="index"
                @click="countryStore.activeCountry = area"
              >
                <ncl-flag :iso="area.code" />
                &nbsp; {{ area.name }}
              </div>
            </div>
          </div>
          <div class="navbar-item has-dropdown is-hoverable">
            <span class="navbar-link">{{ activeTargetPeriod }}</span>
            <div
              class="navbar-dropdown is-boxed"
              v-if="countryStore.activeIndicator.indicator === 'gdp'"
            >
              <div
                class="navbar-item dropdown-option"
                v-for="(targetPeriod, index) in countryStore.quarters"
                :key="index"
                @click="activeTargetPeriod = targetPeriod.name"
              >
                {{ targetPeriod.name }}
              </div>
            </div>
          </div>
          <div class="navbar-item">
            <detail-report-link :active-target-period="activeTargetPeriod" />
          </div>
          <div
            class="navbar-item is-hidden-touch is-hidden-desktop-only is-hidden-widescreen-only"
          >
            <p>
              GDP Forecast ({{ currentDate }}):
              <span class="title is-6" v-if="currentIndicatorValue"
                >{{ currentIndicatorValue }}%</span
              >
              <span class="title is-6" v-else>No data</span>
            </p>
          </div>
          <div class="navbar-item is-hidden-fullhd">
            <span v-if="currentIndicatorValue">{{
              currentIndicatorValue
            }}</span>
            <span v-else>No data</span>
          </div>
          <div class="navbar-item">
            <p>
              7-Day Trend: &nbsp;
              <font-awesome-icon
                class="title is-6"
                v-if="currentTrend > 0"
                :icon="['fa', 'long-arrow-up']"
                :transform="{ rotate: 45 }"
              ></font-awesome-icon>
              <font-awesome-icon
                class="title is-6"
                v-if="currentTrend === 0"
                :icon="['fa', 'long-arrow-up']"
                :transform="{ rotate: 90 }"
              ></font-awesome-icon>
              <font-awesome-icon
                class="title is-6"
                v-else-if="currentTrend < 0"
                :icon="['fa', 'long-arrow-down']"
                :transform="{ rotate: -45 }"
              ></font-awesome-icon>
              <font-awesome-icon
                class="title is-6"
                v-else-if="!currentTrend"
                :icon="['fa', 'minus']"
              ></font-awesome-icon>
            </p>
          </div>
          <div class="navbar-item">
            <a
              href="/api/output/download?indicator=en&model=all"
              @click="() => trackEvent('Download')"
              target="_blank"
              download
            >
              Download
              <font-awesome-icon
                :icon="['fa', 'file-download']"
              ></font-awesome-icon>
            </a>
          </div>
        </div>
      </div>
    </nav>
    <div v-if="notifications.length > 0">
      <v-alert
        v-for="n in notifications"
        :type="n.type"
        :key="n.id"
        closable
        close-label="Close notification"
      >
        <template v-slot:text>
          <p v-for="p in n.content" :key="p" v-html="p"></p>
        </template>
      </v-alert>
    </div>
    <div v-if="getLatestNewsItem != undefined">
      <v-alert>
        <news-item :newsItem="getLatestNewsItem" />
        <router-link
          :to="{ name: 'News' }"
          target="_blank"
          class="external-link"
        >
          More news
        </router-link>
      </v-alert>
    </div>
    <section style="position: relative">
      <v-dialog
        transition="dialog-bottom-transition"
        activator="#indicator-info"
        contained
      >
        <template v-slot:default="{ isActive }">
          <v-card>
            <v-toolbar
              color="primary"
              :title="`Current ${countryStore.activeIndicator.name} Growth Forecast`"
            ></v-toolbar>
            <v-card-text>
              <div class="text-body-1 pa-12">
                All displayed GDP growth projections on this website are
                non-annualized quarter-on-quarter growth rates in %. The GDP
                growth projections for the Euro Area are obtained by aggregating
                the GDP growth projections of all Euro Area member countries in
                the Nowcasting Lab. As an alternative to aggregating the Euro
                Area projections from the projections of individual countries,
                the lab also includes direct projection models for Euro Area GDP
                growth. Here, the variable selections, the model
                parameterizations, etc., are done independently of the
                projections for the individual countries. The direct Euro Area
                GDP growth projections are not displayed on the website, but run
                in the background and are available on request.
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn variant="text" @click="isActive.value = false"
                >Close</v-btn
              >
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
      <h1 class="title is-5 title-align-center">
        Current {{ countryStore.activeIndicator.name }} Growth Forecast
        {{ activeTargetPeriod }}: Country Overview
        <span id="indicator-info">
          <ncl-info-icon text="Click for more information" location="bottom" />
        </span>
      </h1>
      <h2 class="subtitle is-6 title-align-center">
        <detail-report-link :active-target-period="activeTargetPeriod">
          Inspect Country and Model Specific Details:
          {{ countryStore.activeCountry.name }}
          {{ activeTargetPeriod }}
          <font-awesome-icon
            :icon="['fa', 'external-link']"
          ></font-awesome-icon>
        </detail-report-link>
      </h2>
      <div>
        <div class="columns is-vcentered is-tablet">
          <div class="column hide-on-mobile is-1">
            <MapLegend
              class="legend"
              :style="{ 'margin-top': legendMarginTop }"
              :indicator-values="indicatorRange"
            >
            </MapLegend>
          </div>
          <div class="column hide-on-mobile is-6-mobile-offset">
            <KOFCASTsMap
              class="kofmap"
              :country-colors="countryColors"
              :indicator-values="indicatorRange"
              :active-indicator="countryStore.activeIndicator.indicator"
              :active-target-period="activeTargetPeriod"
            >
            </KOFCASTsMap>
          </div>
          <div class="column histogram">
            <highcharts
              ref="highcharts"
              :options="histogramOptions"
            ></highcharts>
          </div>
        </div>
      </div>
    </section>
    <h1 class="title is-5 title-align-center">
      Forecast History for {{ countryStore.activeCountry.adjective }}
      {{ countryStore.activeIndicator.name }} Growth {{ activeTargetPeriod }}
    </h1>
    <div>
      <div class="column">
        <highcharts
          ref="highcharts"
          :options="seriesOptions"
          id="areaInfo"
        ></highcharts>
      </div>
    </div>
    <v-dialog activator="#lab-info">
      <template v-slot="{ isActive }">
        <ncl-lab-modal @close="isActive.value = false" />
      </template>
    </v-dialog>
  </div>
</template>

<script>
import { usePlausible } from "v-plausible/vue";
import NewsItem from "@/components/NewsItem.vue";
import KOFCASTsMap from "@/components/NclMap.vue";
import MapLegend from "@/components/MapLegend.vue";
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import DetailReportLink from "@/components/DetailReportLink.vue";
import NclFlag from "@/components/NclFlag.vue";
import NclInfoIcon from "@/components/NclInfoIcon.vue";
import NclLabModal from "@/components/NclLabModal.vue";
import {
  min as d3min,
  max as d3max,
  scaleLinear as d3scaleLinear,
  interpolateRgb as d3interpolateRgb,
} from "d3";
import { Chart } from "highcharts-vue";
import { mapState, mapStores } from "pinia";
import { useCountryStore } from "@/stores/country";
import { useNewsStore } from "@/stores/news";
import { useNotificationsStore } from "@/stores/notifications";

export default {
  setup() {
    const { trackEvent, trackPageview } = usePlausible();
    return { trackEvent, trackPageview };
  },
  components: {
    NewsItem,
    KOFCASTsMap,
    MapLegend,
    highcharts: Chart,
    LoadingOverlay,
    DetailReportLink,
    NclFlag,
    NclInfoIcon,
    NclLabModal,
  },
  computed: {
    ...mapStores(useCountryStore),
    ...mapState(useCountryStore, ["activeCountry", "activeIndicator"]),
    ...mapState(useNewsStore, ["getLatestNewsItem"]),
    ...mapState(useNotificationsStore, ["notifications"]),
    currentIndicatorValue() {
      const val = this.countryStore.getIndicatorValueFromCountry(
        this.countryStore.activeCountry.code,
        this.countryStore.activeIndicator.indicator,
        this.activeTargetPeriod,
      );
      if (val) {
        const indicatorValue = val.toFixed(2);
        return indicatorValue > 0
          ? `+${indicatorValue}`
          : indicatorValue.toString();
      }
      return "";
    },
    minMaxTargetPeriodDates() {
      if (
        this.countryStore.activeCountry &&
        this.countryStore.activeIndicator.indicator &&
        this.activeTargetPeriod
      ) {
        let data = this.countryStore.minMaxTargetPeriodDates(
          this.countryStore.activeCountry.code,
          this.countryStore.activeIndicator.indicator,
          this.activeTargetPeriod,
        );
        return data;
      }
      return [null, null];
    },
    currentDate() {
      const options = { year: "numeric", month: "short", day: "numeric" };
      const data = this.countryStore.getIndicatorTPHistory(
        this.countryStore.activeCountry,
        this.countryStore.activeIndicator.indicator,
        this.activeTargetPeriod,
      );
      if (data.length > 0) {
        const date = new Date(data[data.length - 1][0]);
        return date.toLocaleDateString("en-US", options);
      }
      return "";
    },
    indicatorRange() {
      let min = d3min(this.countryStore.areas, (d) => {
        if (this.countryStore.activeIndicator) {
          return this.countryStore.getIndicatorValueFromCountry(
            d.code,
            this.countryStore.activeIndicator.indicator,
            this.activeTargetPeriod,
          );
        }
        return 0;
      });

      let max = d3max(this.countryStore.areas, (d) => {
        if (this.countryStore.activeIndicator) {
          return this.countryStore.getIndicatorValueFromCountry(
            d.code,
            this.countryStore.activeIndicator.indicator,
            this.activeTargetPeriod,
          );
        }
        return 0;
      });

      return [min, max];
    },
    countryColors() {
      let minMax = this.indicatorRange;
      let firstColor = this.countryStore.colors[0];
      let lastColor =
        this.countryStore.colors[this.countryStore.colors.length - 1];

      let colorRange = [firstColor, lastColor];
      const color = d3scaleLinear()
        .domain(minMax)
        .range(colorRange)
        .interpolate(d3interpolateRgb, this.countryStore.colors);

      let colors = {};
      for (let a in this.countryStore.areas) {
        let area = this.countryStore.areas[a];
        for (let x in area.countries) {
          let id = area.countries[x];
          let indicator = this.countryStore.getIndicatorValueFromCountry(
            area.code,
            this.countryStore.activeIndicator.indicator,
            this.activeTargetPeriod,
          );
          if (indicator == null) {
            colors[id] = {
              color: null,
            };
          } else {
            colors[id] = {
              color: color(indicator),
            };
          }
        }
      }
      return colors;
    },
    currentTrend() {
      /* Calculates 7-Day forecasting trend:
       *  If there are less than eight forecasts subtract the newest entry in the array from the oldest
       *  Else: Calculate seven-day window and subtract newest entry of array from the oldest in the window
       */
      const data = this.countryStore.getIndicatorTPHistory(
        this.countryStore.activeCountry,
        this.countryStore.activeIndicator.indicator,
        this.activeTargetPeriod,
      );
      const forecastWindow = 8;
      let latestForecast = 0;
      let oldestForecast = 0;
      if (data.length > 0) {
        latestForecast = data[data.length - 1][1];
        oldestForecast =
          data.length < forecastWindow
            ? data[0][1]
            : data[data.length - forecastWindow][1];
      }
      return latestForecast - oldestForecast;
    },
    legendMarginTop() {
      if (window.outerWidth >= 1024) {
        return "0%";
      }
      return "30%";
    },
    activeTargetPeriod: {
      get() {
        if (this.selectedTargetPeriod) {
          return this.selectedTargetPeriod;
        }
        if (this.countryStore.quarters.length) {
          return this.countryStore.quarters[1].name;
        }
        return "";
      },
      set(newPeriod) {
        this.loadGraphs(newPeriod);
        this.selectedTargetPeriod = newPeriod;
      },
    },
    seriesOptions() {
      const data = this.countryStore.getIndicatorTPHistory(
        this.countryStore.activeCountry,
        this.countryStore.activeIndicator.indicator,
        this.activeTargetPeriod,
      );

      const series = {
        name: "GDP growth forecast",
        showInLegend: false,
        marker: {
          enabled: false,
        },
        data: data,
      };

      return {
        chart: {
          style: {
            fontFamily: "IBM Plex Sans",
            fontSize: "14px",
          },
        },
        title: {
          text: "",
          style: {
            fontWeight: "bold",
          },
        },
        yAxis: [
          {
            title: {
              text: "Quarter-on-quarter growth in %",
            },
            minRange: 0.2,
          },
        ],
        xAxis: {
          title: {
            text: "Date of forecast",
          },
          type: "datetime",
          dateTimeLabelFormats: {
            month: "%b %Y",
            year: "%b %Y",
          },
          tickInterval: 30 * 24 * 3600 * 1000,
          min: this.minMaxTargetPeriodDates[0],
          max: this.minMaxTargetPeriodDates[1],
        },
        tooltip: {
          valueDecimals: 2,
          valueSuffix: "%",
        },
        series: [series],
      };
    },
    histogramOptions() {
      const series = {
        name: "GDP growth forecast",
        showInLegend: false,
        data: this.countryStore.areas
          .map((area) => {
            const color =
              this.countryStore.activeCountry.name === area.name
                ? "#FFA500"
                : "#7cb5ec";

            const value = this.countryStore.getIndicatorValueFromCountry(
              area.code,
              this.countryStore.activeIndicator.indicator,
              this.activeTargetPeriod,
            );

            return {
              y: value,
              name: area.name,
              color: color,
            };
          })
          .sort((a, b) => {
            return b.y - a.y;
          }),
      };

      return {
        chart: {
          style: {
            fontFamily: "IBM Plex Sans",
          },
          type: "bar",
        },
        title: {
          text: "",
          style: {
            fontWeight: "bold",
          },
        },
        xAxis: {
          type: "category",
          labels: {
            style: {
              fontSize: "14px",
            },
          },
        },
        yAxis: [
          {
            title: {
              text: "Quarter-on-quarter growth in %",
              style: {
                fontSize: "13px",
              },
            },
            labels: {
              style: {
                fontSize: "13px",
              },
            },
          },
        ],
        tooltip: {
          valueDecimals: 2,
          valueSuffix: " %",
        },
        series: [series],
        plotOptions: {
          series: {
            cursor: "pointer",
            point: {
              events: {
                click: (d) => {
                  this.countryStore.setActiveCountry(d.point.name);
                },
              },
            },
          },
        },
      };
    },
  },
  methods: {
    async loadGraphs(targetPeriod) {
      this.isLoading = true;
      await this.countryStore.loadData(targetPeriod);
      this.isLoading = false;
    },
  },
  data() {
    return {
      valueNames: [{ name: "GDP", indicator: "gdp" }],
      selectedTargetPeriod: null,
      openNavigation: false,
      isLoading: true,
      isOpen: false,
      modalIsActive: false,
      isIndicatorModalOpen: false,
    };
  },
  mounted() {
    this.isLoading = true;
    this.countryStore
      .loadQuarters()
      .then(() => this.loadGraphs(this.activeTargetPeriod));
    this.trackPageview(undefined, {});
  },
};
</script>

<style lang="scss">
@import url("bulma/css/bulma.min.css");

.navbar-item .navbar-dropdown.is-boxed {
  visibility: visible;
}

@media (min-width: 1088px) {
  .navbar-item.is-hoverable:hover .navbar-dropdown {
    display: block;
  }

  .navbar-item.is-hoverable:hover .navbar-dropdown.is-boxed {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
  }
}

html,
body {
  margin: 0;
  padding: 0;
  padding-top: 15px;
  height: 100vh;
  width: 100vw;
}

.kofmap {
  width: 100%;
  height: 40vh;
}

.histogram {
  margin-top: auto;
}

.hide-on-mobile {
  display: flex;
}

@media screen and (max-width: 500px) {
  .hide-on-mobile {
    display: none;
  }
}

.navbar-item.dropdown-option {
  cursor: pointer;
}

.navbar-item.dropdown-option:hover {
  background-color: #eee;
}

.navbar.is-fixed-top + * {
  margin-top: 32px;
}

.title-align-center {
  text-align: center;
}

.link:hover {
  color: rgb(124, 181, 236);
}

.navbar-link:hover {
  color: rgb(124, 181, 236);
}

.navbar-link:not(.is-arrowless)::after {
  border-color: rgb(124, 181, 236);
}

.button.is-rounded:hover {
  border-color: rgb(124, 181, 236);
}

@media screen and (min-width: 1023px) and (max-width: 1380px) {
  .navbar-item.show-on-wide-small {
    display: None;
  }

  .navbar-item.hide-on-wide-small {
    display: flex;
  }
}

.legend {
  position: absolute;
}

h1 .info-icon {
  font-size: 12pt;
}
</style>
