<template>
  <div class="table-wrapper">
    <table v-if="currentReleaseData.length" class="release-table">
      <tr class="header">
        <th></th>
        <th>Data Series</th>
        <th>Reference period</th>
        <th>Impact</th>
      </tr>
      <tr
        v-for="(row, index) in currentReleaseData"
        :key="index"
        @click="$emit('row-click', index, row)"
      >
        <td>
          <div class="category-color-box-wrapper">
            <div
              :style="{ backgroundColor: colorForTable(row) }"
              class="category-color-box"
            ></div>
          </div>
        </td>
        <td>{{ formatTitle(row.title) }}</td>
        <td>{{ row.referencePeriod }}</td>
        <td>{{ formatImpactValue(row.impact) }}</td>
      </tr>
    </table>
    <div v-else class="nodata-wrapper">
      <div>No new data releases</div>
    </div>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { useDetailStore } from "@/stores/detail";

export default {
  name: "ReleaseTable",
  props: {
    activeCategories: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState(useDetailStore, [
      "getReleaseData",
      "getActiveDay",
      "getCategories",
    ]),
    currentReleaseData() {
      let currentData = this.getReleaseData[this.getActiveDay] || [];
      currentData = currentData.sort((a, b) =>
        a.category > b.category ? 1 : -1,
      );

      return currentData.filter((obj) => {
        return this.activeCategories.some((item) => item.name === obj.category);
      });
    },
  },
  methods: {
    colorForTable(data) {
      let category = data.category;
      if (category) {
        let catData = this.getCategories.find((obj) => obj.name === category);
        if (catData) {
          return catData["color"];
        }
      }
      return null;
    },
    formatValue(value) {
      if (typeof value === "number") {
        return value.toFixed(2);
      }
      return value;
    },
    formatImpactValue(value) {
      return Number.parseFloat(value).toPrecision(2);
    },
    formatTitle(title) {
      const maxLength = 15;
      return (
        title.slice(0, maxLength) + (maxLength < title.length ? "..." : "")
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.table-wrapper {
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;

  .release-table {
    overflow-x: scroll;
    font-size: 0.9em;

    .header {
      position: sticky;
      top: 0;
      background-color: white;
    }

    tr:hover td {
      background-color: #f5f7fa;
    }

    tr {
      border-bottom: 1px solid #dcdfe6;
    }

    td,
    th {
      white-space: nowrap;
      padding: 5px 15px;
    }

    td {
      cursor: pointer;
    }

    .category-color-box-wrapper {
      padding-top: 6px;

      .category-color-box {
        width: 11px;
        height: 11px;
      }
    }
  }

  .nodata-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
